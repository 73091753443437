import { GET_USER_BALANCE, CURRENT_BETS, BETS_HISTORY } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_USER_BALANCE:
			return {
				...state,
				currentBalance: action.payload
			};
		case CURRENT_BETS:
			return {
				...state,
				currentBets: action.payload.reverse()
			};
		case BETS_HISTORY:
			return {
				...state,
				betsHistory: action.payload.reverse()
			};
		default:
			return state;
	}
}
