import React, { Component } from 'react';
import { getEthHistory } from '../../actions/bettingDataActions';
import axios from 'axios';
import moment from 'moment-timezone';

class BettingData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//bettingAgainst: 0,
			bettingAgainstPrice: 0,
			currentEthPrice: 0,
			priceDifference: 0,
			showDifferencer: false
		};
	}

	getRoundID = () => {
		return new Promise((resolve, reject) => {
			resolve(
				axios
					.post('/api/roundsHistory/getRoundData')
					.then(response => response.data)
					.then(data => {
						this.setState({
							bettingAgainstPrice: data.betPrice
						});
					})
			);
		});
	};

	calculatePriceDifference = () => {
		return new Promise((resolve, reject) => {
			resolve(
				this.setState({
					priceDifference: (
						(this.state.currentEthPrice / this.state.bettingAgainstPrice - 1) *
						100
					).toFixed(2),
					showDifferencer: true
				})
			);
		});
	};

	componentDidMount() {
		Promise.all([this.getRoundID(), getEthHistory.call(this)]).then(
			this.calculatePriceDifference
		);
		this.timer = setInterval(() => {
			getEthHistory.call(this).then(this.calculatePriceDifference);
			Promise.all([getEthHistory.call(this)]).then(
				this.calculatePriceDifference
			);
		}, 12000);

		// check if new round is started
		this.newRoundTimer = setInterval(() => {
			if (
				(moment(global.timeNow).get('minute') === 1 ||
					moment(global.timeNow).get('minute') === 11 ||
					moment(global.timeNow).get('minute') === 21 ||
					moment(global.timeNow).get('minute') === 31 ||
					moment(global.timeNow).get('minute') === 41 ||
					moment(global.timeNow).get('minute') === 51) &&
				moment(global.timeNow).get('second') < 30
			) {
				Promise.all([this.getRoundID()]).then(this.calculatePriceDifference);
			}
		}, 1000);
	}

	toggleDifferencer() {
		this.setState({
			showDifferencer: !this.state.showDifferencer
		});
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		clearInterval(this.newRoundTimer);
	}

	render() {
		return (
			<React.Fragment>
				<p className='inner-data-title'>Betting Data</p>
				{this.state.showDifferencer ? (
					<div
						className='data-val-wrap'
						onChange={this.toggleDifferencer.bind(this)}>
						<p>Betting Against</p>
						<p className='data-white-text'>{this.state.bettingAgainstPrice}</p>
					</div>
				) : null}
				{this.state.showDifferencer ? (
					<div
						className='data-val-wrap mar-bot'
						onChange={this.toggleDifferencer.bind(this)}>
						<p>Current ETH Price</p>
						<p className='data-white-text'>{this.state.currentEthPrice}</p>
					</div>
				) : null}
				{this.state.showDifferencer ? (
					<div
						className='data-val-wrap-bot'
						onChange={this.toggleDifferencer.bind(this)}>
						<p
							className={
								this.state.priceDifference < 0
									? 'data-red-text'
									: 'data-green-text'
							}>
							{this.state.priceDifference < 0
								? ''
								: this.state.priceDifference > 0
								? '+'
								: ''}
							{this.state.priceDifference}%
						</p>
						<p className='big-text'>Price Difference</p>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

export default BettingData;
