import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Landing extends Component {
	goToLogin = () => {
		const { user } = this.props.auth;
		if (user.id !== '' && user.id !== undefined) {
			let { history } = this.props;
			history.push({
				pathname: '/login'
			});
		}
	};

	componentDidMount() {
		this.goToLogin();
	}

	render() {
		return (
			<div className='container valign-wrapper'>
				<div className='row landing-center'>
					<div className='landing-wrap'>
						<div className='landing-text-hold'>
							<p className='new-customer'>NEW CUSTOMER OFFER</p>
							<p className='risk-free'>
								GET A <br /> RISK-FREE
							</p>
							<p className='eth-number'>5 ETH</p>
						</div>
						<Link
							to='/register'
							className='btn btn-large waves-effect waves-light hoverable blue accent-3'>
							Register
						</Link>
						<p className='landing-text'>
							This is placeholder of some text that will attract new customers.
							This text need to help new visitor to think less when he need to
							decide will he make account or not.
						</p>
						<div className='terms-help-wrap'>
							<Link to='/terms'>Terms</Link>
							<Link to='/help'>Help</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Landing.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(withRouter(Landing));
