import axios from 'axios';
import {
	GET_ERRORS,
	GET_USER_BALANCE,
	CURRENT_BETS,
	BETS_HISTORY
} from './types';

// Get user balance
export const userBalance = x => {
	return {
		type: GET_USER_BALANCE,
		payload: x
	};
};

// Get current bet user & round id
export const currentBets = roundId => dispatch => {
	axios
		.post('/api/currentBets/getCurrentBets', {
			userid: global.userID,
			roundid: roundId
		})
		.then(response => response.data)
		.then(data => {
			let dataOnBlockchain = [];
			data.forEach(element => {
				if (element['onBlockchain'] === true) {
					dataOnBlockchain.push(element);
				}
			});
			dispatch(currentUserBets(dataOnBlockchain));
		});
};

// Pass currentUser data to reducer
export const currentUserBets = data => {
	return {
		type: CURRENT_BETS,
		payload: data
	};
};

// Get all bets
export const betsHistory = () => dispatch => {
	axios
		.post('/api/currentBets/getAllBets', { userid: global.userID })
		.then(response => response.data)
		.then(data => {
			dispatch(allBetsHistory(data));
		});
};

// Pass all bets history data to reducer
export const allBetsHistory = data => {
	return {
		type: BETS_HISTORY,
		payload: data
	};
};

/*export function getEthPrice() {
	return new Promise((resolve, reject) => {
		resolve(
			axios
				.post('/api/ethHistories/getEthData')
				.then(response => response.data)
				.then(data => {
					this.setState({
						bettingAgainst: data.value,
						currentEthPrice: data.value,
						priceDifference: (
							(this.state.currentEthPrice / this.state.bettingAgainst - 1) *
							100
						).toFixed(2)
					});
				})
		);
	});
}*/

export function getEthHistory() {
	return new Promise((resolve, reject) => {
		resolve(
			axios
				.post('/api/ethHistories/getEthData')
				.then(response => response.data)
				.then(data => {
					this.setState({
						currentEthPrice: data.value
						//priceDifference: (
						//(this.state.currentEthPrice / this.state.bettingAgainstPrice -
						//1) *
						//100
						//).toFixed(2)
					});
				})
			//.then(this.setState({ showDifferencer: true }))
		);
	});
}

export function getAddressPassword(userAddress) {
	//console.log('Address obtaining STARTED');
	axios
		.post('/api/addresses/getAddressPass', { address: userAddress })
		.then(response => console.log(response));
}

//export function getCurrentBets(userid, roundid) {
//console.log("Hej");
//axios
//.post("/api/currentBets/getCurrentBets", {userid: userid, roundid: roundid})
//.then(response => console.log(response));
//.then(response => response.data)
//.then(data => global.currentBets.push(data.pop()));
//}

export const insertIntoBetHistory = (
	userid,
	bettype,
	amount,
	roundid,
	betid,
	timestamp
) => dispatch => {
	axios
		.post('/api/insertHistory/insertbh', {
			userid: userid,
			bettype: bettype,
			amount: amount,
			roundid: roundid,
			betid: betid,
			timestamp: timestamp
		})
		.then()
		.catch(err =>
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		);
};
