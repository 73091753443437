import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { logoutUser } from '../../actions/authActions';
import classnames from 'classnames';
import { userBalance } from '../../actions/bettingDataActions';

import Web3 from 'web3';
import nodeUrl from '../../eth-node-config.json';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visibility: false,
			currentBalance: 0.0,
			navVisibility: true,
			mobile: 0
		};
	}

	componentDidMount() {
		this._mounted = true;
		this.getUserBalance();
		window.addEventListener('resize', this.resize.bind(this));
		this.resize();
	}

	componentWillMount() {
		window.addEventListener('resize', this.handleNavVisibility);
	}

	componentWillUnmount() {
		this._mounted = false;
		window.removeEventListener('resize', this.handleNavVisibility);
	}

	getUserBalance = () => {
		const web3 = new Web3(nodeUrl.url);
		const { user } = this.props.auth;
		if (user.address !== '' && user.address !== undefined) {
			web3.eth.getBalance(user.address).then(balance => {
				if (balance) {
					const x = web3.utils.fromWei(balance, 'ether');
					this.props.userBalance(x);
				}
			});
		}
	};

	handleEyeVisibility = () => {
		this.setState({ visibility: !this.state.visibility });
		this.getUserBalance();
	};

	handleNavVisibility = () => {
		this.state.mobile
			? this.setState({
					navVisibility: !this.state.navVisibility
			  })
			: this.setState({
					navVisibility: true
			  });
	};

	onLogoutClick = e => {
		e.preventDefault();
		this.props.logoutUser(this.props.history);
	};

	resize() {
		this.setState({
			mobile: window.innerWidth <= 767,
			navVisibility: window.innerWidth > 767
		});
	}

	render() {
		const { user } = this.props.auth;
		const bettingData = this.props.bettingData;

		return (
			<div
				className='container-fluid navbar-fixed nav-wrap'
				ref={el => (this.container = el)}>
				<nav className='z-depth-0'>
					<div className='container nav-wrapper darker-bg logofont app-nav'>
						<div
							className={classnames(
								'row nav-row',
								user.id !== '' && user.id !== undefined
									? 'logged'
									: 'not-logged'
							)}>
							<div className='logo-wrap max-height logo-wrap'>
								<span>
									<a href='/dashboard'>Betcoin</a>
								</span>
							</div>
							<div
								className={classnames(
									'max-height nav-wrapz',
									this.props.history.location.pathname === '/login'
										? 'login-page'
										: 'logged-registered'
								)}>
								{user.id !== '' && user.id !== undefined ? (
									this.state.navVisibility ? (
										<ul className='list-wrap logged-btns-wrap'>
											<li onClick={this.handleNavVisibility}>
												<NavLink to='/dashboard' activeClassName='active-link'>
													Dashboard
												</NavLink>
											</li>
											<li onClick={this.handleNavVisibility}>
												<NavLink to='/wallet' activeClassName='active-link'>
													Wallet
												</NavLink>
											</li>
											<li onClick={this.handleNavVisibility}>
												<NavLink to='/terms' activeClassName='active-link'>
													Terms
												</NavLink>
											</li>
											<li onClick={this.handleNavVisibility}>
												<NavLink to='/help' activeClassName='active-link'>
													Help
												</NavLink>
											</li>
											<li onClick={this.handleEyeVisibility} className='eye'>
												<a href='#eye'>
													Balance
													{/* <i className='material-icons'>remove_red_eye</i> */}
													<img
														className='nav-eye'
														src={require('../../img/eye2.svg')}
														alt=''
													/>
												</a>
											</li>
											{this.state.visibility ? (
												<li onClick={this.handleEyeVisibility}>
													<a href='#bal'>{bettingData.currentBalance}</a>
												</li>
											) : null}
											<li>
												<DropdownButton
													id='dropdown-basic-button'
													title={user.name}>
													<Dropdown.Item>Edit account</Dropdown.Item>
													<Dropdown.Item onClick={this.onLogoutClick}>
														Logout
													</Dropdown.Item>
												</DropdownButton>
											</li>
										</ul>
									) : null
								) : (
									<ul
										className={classnames(
											'list-wrap login-btns-wrap',
											this.props.history.location.pathname === '/register' ||
												this.props.history.location.pathname === '/'
												? 'register-page'
												: 'logged-registered'
										)}>
										<li>
											<Link
												to='/login'
												className='btn btn-large waves-effect waves-light hoverable blue accent-3 link-login'>
												Login
											</Link>
										</li>
										<li>
											<Link
												to='/register'
												className='btn btn-large waves-effect waves-light hoverable blue accent-3 link-register'>
												Register
											</Link>
										</li>
									</ul>
								)}
							</div>
							<div className='burger-menu' onClick={this.handleNavVisibility}>
								<img src={require('../../img/burger-menu.png')} alt='' />
							</div>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}

Navbar.propTypes = {
	auth: PropTypes.object.isRequired,
	logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	bettingData: state.bettingData
});

export default connect(
	mapStateToProps,
	{ logoutUser, userBalance }
)(withRouter(Navbar));
