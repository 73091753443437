import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUser, updateUserAdr } from '../../actions/authActions';
import { loginUser } from '../../actions/authActions';
import ValueData from '../ValueData/ValueData';
import BettingData from '../BetData/BettingData';
import RoundData from '../RoundData/RoundData';
import Betting from '../Betting/Betting';
import Graph from '../Graph/Graph';
import HistoryOfBets from '../HistoryOfBets/HistoryOfBets';
import moment from 'moment-timezone';
import axios from 'axios';
import { Link } from 'react-router-dom';

moment.tz.setDefault('Europe/Belgrade');

global.timeNow = '';

class Popup extends React.Component {
	render() {
		return (
			<div className='popup'>
				<div className='popup_inner welcome-pop'>
					<p className='hello-text'>
						<span>Welcome</span>
						<br /> Member
					</p>
					<p className='ethNumber'>You got 5 ETH</p>
					<p className='welcome-info'>
						Your Betcoin account has been succesfully created. We wish you great
						time and good luck. If there is anything you don't understand,
						please visit our <Link to='/help'> help</Link> section.
					</p>
					<button onClick={this.props.closePopup}>Bet</button>
				</div>
			</div>
		);
	}
}

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.handleStartTime = this.handleStartTime.bind(this); // binding method
		this.handleRoundOutcome = this.handleRoundOutcome.bind(this); // binding method
		this.handleNewBet = this.handleNewBet.bind(this); // binding method
		//this.handlePush = pushBet.bind(this);
		this.state = {
			showValueData: true,
			showBettingData: true,
			showRoundData: true,
			showBetting: true,
			showGraph: true,
			showHistoryOfBets: true,
			roundStartTime: '',
			roundOutcome: '',
			newBet: ''
		};
	}
	state = {
		showPopup: true
	};

	componentDidMount() {
		this.setPopupState();
		let { user } = this.props.auth;
		if (user.flogin) {
			this.props.updateUser(user.id).then(this.props.loginUser(user));
		}

		this.getTimeFromServer();
		this.serverTimer = setInterval(() => {
			this.getTimeFromServer();
		}, 60000);

		this.timer = setInterval(() => {
			let incrementedTime = moment(global.timeNow).add(1, 'seconds');
			global.timeNow = incrementedTime.clone();
		}, 1000);
	}

	getTimeFromServer = () => {
		axios
			.post('/api/currentTime/getCurrentTime')
			.then(response => response.data)
			.then(data => {
				global.timeNow = data.time;
			});
	};

	componentWillUnmount = () => {
		clearTimeout(this.timer);
		clearTimeout(this.serverTimer);
	};

	togglePopup() {
		this.setState({
			showPopup: !this.state.showPopup
		});
	}
	setPopupState = () => {
		const { user } = this.props.auth;
		this.setState({
			showPopup: user.flogin
		});
	};

	handleStartTime(value) {
		this.setState(prevState => {
			return {
				roundStartTime: value
			};
		});
	}

	handleNewBet(value) {
		this.setState(prevState => {
			return {
				newBet: value
			};
		});
	}

	handleRoundOutcome(value) {
		this.setState(prevState => {
			return {
				roundOutcome: value
			};
		});
	}

	render() {
		let valueData = null;
		if (this.state.showValueData) {
			valueData = <ValueData />;
		}

		let bettingData = null;
		if (this.state.showBettingData) {
			bettingData = <BettingData roundStartTime={this.state.roundStartTime} />;
		}

		let roundData = null;
		if (this.state.showRoundData) {
			roundData = (
				<RoundData
					handleStartTime={this.handleStartTime}
					handleRoundOutcome={this.handleRoundOutcome}
				/>
			);
		}

		let betting = null;
		if (this.state.showBetting) {
			betting = (
				<Betting
					roundStartTime={this.state.roundStartTime}
					handleNewBet={this.handleNewBet}
				/>
			);
		}

		let graph = null;
		if (this.state.showGraph) {
			graph = <Graph />;
		}

		let historyOfBets = null;
		if (this.state.showHistoryOfBets) {
			historyOfBets = (
				<HistoryOfBets
					newBet={this.state.newBet}
					roundStartTime={this.state.roundStartTime}
					roundOutcome={this.state.roundOutcome}
				/>
			);
		}

		return (
			<div className='container-fluid dark-bg my-app'>
				<div className='container'>
					<div className='row main-wrap'>
						<div className='col-lg-9 col-md-7'>{graph}</div>
						<div className='col-lg-3 col-md-5 betting-wrap'>{betting}</div>
						{/* <div className='col-lg-3 col-md-12 sidebar'>
							<div className='row'>							
							</div>
						</div> */}
					</div>
					<div className='row cols-wrap'>
						<div className='col-lg-3 col-md-6 dashboard-data-col'>
							<div className='inner-data-wrap'>{roundData}</div>
						</div>
						<div className='col-lg-3 col-md-6 dashboard-data-col'>
							<div className='inner-data-wrap'>{valueData}</div>
						</div>
						<div className='col-lg-3 col-md-6 dashboard-data-col'>
							<div className='inner-data-wrap'>{bettingData}</div>
						</div>
						<div className='col-lg-3 col-md-6 dashboard-data-col robot shadow-wrap'>
							{historyOfBets}
						</div>
					</div>
				</div>
				{this.state.showPopup ? (
					<Popup closePopup={this.togglePopup.bind(this)} />
				) : null}
			</div>
		);
	}
}

Dashboard.propTypes = {
	updateUser: PropTypes.func.isRequired,
	updateUserAdr: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{ updateUser, loginUser, updateUserAdr }
)(Dashboard);
