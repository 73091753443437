import React, { Component } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import Web3 from 'web3';
import nodeUrl from '../../eth-node-config.json';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	userBalance,
	currentBets,
	betsHistory
} from '../../actions/bettingDataActions';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Create web3 instance
 */
const web3 = new Web3(nodeUrl.url);

moment.tz.setDefault('Europe/Belgrade');
global.betoutcome = '';

class HistoryOfBets extends Component {
	state = {
		bets: [],
		allBets: [],
		roundid: '',
		betHistorysTab: false
	};

	showCurrentBetsTrick = () => {
		this.getRoundID().then(result => {
			// Call to currentBets action
			this.props.currentBets(result._id);
		});
	};

	componentDidMount() {
		// Call to betHistory action
		this.props.betsHistory();
		this.showCurrentBetsTrick();
		let popUpNotShown = true;
		this.revealTab('cBet');

		this.timer = setInterval(() => {
			// Check if round is finished
			if (
				moment(new Date(this.props.roundStartTime))
					.add(8, 'minutes')
					.diff(moment(new Date(global.timeNow))) < 0
			) {
				// Check Round Outcome
				if (this.props.roundOutcome !== -1 && popUpNotShown) {
					//round is finished, set winning result for all bets in list

					global.betoutcome = this.getBetTypeString(this.props.roundOutcome);
					this.showCurrentBetsTrick();
					this.props.betsHistory();
					this.notifyRoundOutcome(global.betoutcome);
					popUpNotShown = false;
				}
				this.getUserBalance();
			} else {
				popUpNotShown = true;
			}

			//Update roundid and bets list in first seconds of new round
			if (
				moment(new Date(this.props.roundStartTime))
					.add(5, 'seconds')
					.diff(moment(new Date(global.timeNow))) > 0
			) {
				this.showCurrentBetsTrick();
			}
		}, 2000);
	}

	// Get user balance
	getUserBalance = () => {
		const { user } = this.props.auth;
		if (user.address !== '' && user.address !== undefined) {
			web3.eth.getBalance(user.address).then(balance => {
				if (balance) {
					const x = web3.utils.fromWei(balance, 'ether');
					this.props.userBalance(x);
				}
			});
		}
	};

	getBetTypeString = val => {
		if (val === 2) {
			return 'UP';
		} else if (val === 1) {
			return 'DOWN';
		} else return 'TIE';
	};

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	getRoundID = () => {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/roundsHistory/getRoundData')
				.then(response => response.data)
				.then(data => {
					this.setState(
						{
							roundid: data._id
						},
						() => {
							resolve(data);
						}
					);
				});
		});
	};

	// Tab reveal for bets history
	revealTab = value => {
		if (value === 'cBet') {
			this.setState({
				currentBetsTab: true,
				betHistorysTab: false
			});
		} else {
			this.setState({
				currentBetsTab: false,
				betHistorysTab: true
			});
		}
	};

	notifyRoundOutcome = outcome => {
		toast.info('The winning bet for this round is ' + outcome, {
			position: 'top-right',
			autoClose: 30000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true
		});
	};

	render() {
		const { currentBets, betsHistory } = this.props.bettingData;
		const { currentBetsTab, betHistorysTab } = this.state;
		return (
			<div className='current-bet-wrap lighter-gray-bg bet-history'>
				<ToastContainer
					position='top-right'
					autoClose={30000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
				<div className='bet-btns-wrap'>
					<button
						className={classnames(
							'current-bets-btn',
							this.state.betHistorysTab ? '' : 'active'
						)}
						onClick={() => this.revealTab('cBet')}>
						Current bets
					</button>
					<button
						className={classnames(
							'all-bets-btn',
							betHistorysTab ? 'active' : ''
						)}
						onClick={this.revealTab}>
						Bets history
					</button>
				</div>
				{currentBetsTab ? (
					<ul className='current-bets-list'>
						{currentBets !== undefined &&
							currentBets.map(bet => (
								<li key={Math.random()}>
									<span
										className={classnames(
											'won-lost',
											bet.betoutcome === 'lost' ? 'lost-text' : 'won-text'
										)}>
										{bet.betoutcome === '-' ? '' : bet.betoutcome}
									</span>
									{bet.bettype === 1 ? (
										<span className='current-down'>
											<img src={require('../../img/down-arrow.svg')} alt='' />
											Down
										</span>
									) : (
										<span className='current-up'>
											<img src={require('../../img/up-arrow.svg')} alt='' />
											Up
										</span>
									)}{' '}
									<span className='all-bets-eth-amount'>
										{web3.utils.fromWei(bet.amount.toString(), 'ether')} ETH
									</span>{' '}
									<span className='all-bets-date-time'>
										{moment(bet.timestamp).format('DD/MM/YYYY HH:mm')}
									</span>
								</li>
							))}
					</ul>
				) : null}

				{betHistorysTab ? (
					<ul className='all-bets-list'>
						{betsHistory !== undefined &&
							betsHistory.map(bet => (
								<li key={Math.random()}>
									<span
										className={classnames(
											'won-lost',
											bet.betoutcome === 'lost' ? 'lost-text' : 'won-text'
										)}>
										{bet.betoutcome === '-' ? '' : bet.betoutcome}
									</span>
									{bet.bettype === 1 ? (
										<span className='current-down'>
											<img src={require('../../img/down-arrow.svg')} alt='' />
											Down
										</span>
									) : (
										<span className='current-up'>
											<img src={require('../../img/up-arrow.svg')} alt='' />
											Up
										</span>
									)}{' '}
									<span className='all-bets-eth-amount'>
										{web3.utils.fromWei(bet.amount.toString(), 'ether')} ETH
									</span>{' '}
									<span className='all-bets-date-time'>
										{moment(bet.timestamp).format('DD/MM/YYYY HH:mm')}
									</span>
								</li>
							))}
					</ul>
				) : null}
				<div className='shadowEl' />
			</div>
		);
	}
}

HistoryOfBets.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	bettingData: state.bettingData
});

export default connect(
	mapStateToProps,
	{ userBalance, currentBets, betsHistory }
)(HistoryOfBets);
