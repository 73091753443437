import React from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

moment.tz.setDefault('Europe/Belgrade');

class Graph extends React.Component {
	state = {
		chart: '',
		candleTime: 1
	};

	componentDidMount() {
		am4core.useTheme(am4themes_dark);
		am4core.options.autoSetClassName = true;
		am4core.options.classNamePrefix = 'timacum-';
		let chart = am4core.create('chartdiv', am4charts.XYChart);

		chart.paddingRight = 13;
		chart.paddingLeft = -10;

		chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H:m:s';

		let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.renderer.grid.template.location = 0;
		dateAxis.renderer.minGridDistance = 60;
		dateAxis.renderer.labels.template.disabled = true;

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.tooltip.disabled = true;

		let series = chart.series.push(new am4charts.CandlestickSeries());
		series.dataFields.dateX = 'date';
		series.showOnInit = false;
		series.dataFields.valueY = 'close';
		series.dataFields.openValueY = 'open';
		series.dataFields.lowValueY = 'low';
		series.dataFields.highValueY = 'high';
		series.tooltipText =
			'Open: [bold] {openValueY.value}[/]\nLow: [bold] {lowValueY.value}[/]\nHigh: [bold] {highValueY.value}[/]\nClose: [bold] {valueY.value}[/]';

		series.dropFromOpenState.properties.fill = am4core.color('#d65b4a');
		series.dropFromOpenState.properties.stroke = am4core.color('#d65b4a');

		series.riseFromOpenState.properties.fill = am4core.color('#66b92e');
		series.riseFromOpenState.properties.stroke = am4core.color('#66b92e');

		chart.cursor = new am4charts.XYCursor();

		let scrollbarX = new am4charts.XYChartScrollbar();
		scrollbarX.series.push(series);
		chart.scrollbarX = scrollbarX;
		chart.scrollbarX.parent = chart.bottomAxesContainer;
		this.getGraphData().then(data => {
			chart.data = data;
			this.setState({
				chart: chart
			});
		});

		this.graphUpdate = setInterval(() => {
			this.updateGraph(this.state.chart);
		}, 24000);
	}

	updateGraph = chart => {
		this.getGraphData().then(data => {
			chart.data = data;
		});
	};

	getGraphData = () => {
		return new Promise((resolve, reject) => {
			let tempData = [];
			axios
				.post('/api/ethvalues/graphdata', {
					limit: this.state.candleTime * 300
				})
				.then(response => {
					response.data.reverse();
					let dataItem = {};
					let timeStartOk = false;
					let firstCandle = true;
					let numOfTicks = this.state.candleTime * 5;
					let revoluiton = 1;
					response.data.forEach(element => {
						if (moment(element.timestamp).get('second') === 0 && !timeStartOk)
							timeStartOk = true;
						else if (
							moment(element.timestamp).get('second') !== 0 &&
							!timeStartOk
						)
							return;

						if (timeStartOk) {
							if (firstCandle) {
								dataItem.date = moment(element.timestamp).format(
									'YYYY-MM-DD HH:mm:ss'
								);
								dataItem.open = element.value;
								dataItem.high = element.value;
								dataItem.low = element.value;
								firstCandle = false;
							} else if (revoluiton !== numOfTicks) {
								if (dataItem.high < element.value)
									dataItem.high = element.value;
								if (dataItem.low > element.value) dataItem.low = element.value;
							} else if (revoluiton === numOfTicks) {
								if (dataItem.high < element.value)
									dataItem.high = element.value;
								if (dataItem.low > element.value) dataItem.low = element.value;
								dataItem.close = element.value;
								tempData.push(dataItem);
								dataItem = {};
								dataItem.date = moment(element.timestamp).format(
									'YYYY-MM-DD HH:mm:ss'
								);
								dataItem.open = element.value;
								dataItem.high = element.value;
								dataItem.low = element.value;
								revoluiton = 0;
							}
							revoluiton++;
						}
					});
					resolve(tempData);
				});
		});
	};

	changeCandleTime = newCandleTime => {
		this.setState(
			{
				candleTime: newCandleTime.target.value
			},
			() => {
				this.updateGraph(this.state.chart);
			}
		);
	};

	componentWillUnmount = () => {
		if (this.state.chart) {
			this.state.chart.dispose();
		}
		clearInterval(this.graphUpdate);
	};

	render() {
		return (
			<div className='chart-wrapper'>
				<div className='graph-list-wrap'>
					<select onChange={this.changeCandleTime}>
						<option value={1}>1m</option>
						<option value={5}>5m</option>
						<option value={30}>30m</option>
						<option value={60}>1h</option>
						<option value={120}>2h</option>
					</select>
				</div>
				<div id='chartdiv' style={{ width: '100%', height: '400px' }} />
			</div>
		);
	}
}

export default Graph;
