import React, { Component } from 'react';
import Web3 from 'web3';
import nodeUrl from '../../eth-node-config.json';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
var QRCode = require('qrcode.react');

const web3 = new Web3(nodeUrl.url);

class Withdraw extends Component {
	constructor(withdrawProps) {
		super(withdrawProps);
		this.state = {
			inputValue: '',
			inputAddress: '',
			successAlert: false,
			successInfo: false,
			successError: false,
			formErrorsValue: {
				inputValue: ''
			},
			formErrorsAddress: {
				inputAddress: ''
			},
			walletAddress: '',
			QRvisible: false,
			disablebutton: false,
			currentBalance: 0,
			copied: false,
			addressPass: ''
		};
	}

	componentDidMount() {
		const { user } = this.props.auth;
		this.setState({
			walletAddress: user.address
		});
		this.getUserBalance();
		this.getUserAddressAndPassword();
	}

	ErrorFalse = () => {
		this.setState({
			successError: false
		});
	};
	InfoFalse = () => {
		this.setState({
			successInfo: false
		});
	};
	succesFalse = () => {
		this.setState({
			successAlert: false
		});
	};
	/* QR show - hide */
	QRcodeShowHide = () => {
		this.setState({
			QRvisible: !this.state.QRvisible
		});
	};
	/**
	 * Get user balance
	 */
	getUserBalance = () => {
		const { user } = this.props.auth;
		web3.eth.getBalance(user.address).then(balance => {
			this.setState({
				currentBalance: web3.utils.fromWei(balance, 'ether')
			});
		});
	};

	// update value state
	updateAddress = e => {
		e.preventDefault();

		const { value } = e.target;
		let formErrorsAddress = { ...this.state.formErrorsAddress };

		formErrorsAddress.inputAddress =
			value.length === 0 ? 'Please enter wallet address' : '';
		this.setState({ formErrorsAddress, inputAddress: value });
	};

	updateValue = e => {
		e.preventDefault();

		const { value } = e.target;
		let formErrorsValue = { ...this.state.formErrorsValue };

		formErrorsValue.inputValue = value.length === 0 ? 'Please enter value' : '';
		this.setState({ formErrorsValue, inputValue: value });
	};

	changeBtnState = () => {
		this.setState({
			disablebutton: !this.state.disablebutton
		});
	};

	getUserAddressAndPassword = () => {
		const { user } = this.props.auth;
		global.userID = user.id;
		if (user.address !== '' && user.address !== undefined) {
			axios
				.post('/api/addresses/getAddressPass', { address: user.address })
				.then(response => {
					this.setState({
						addressPass: response.data.password
					});
				});
		}
	};

	handleWithward = e => {
		let formErrorsAddress = { ...this.state.formErrorsAddress };
		let formErrorsValue = { ...this.state.formErrorsValue };
		const { user } = this.props.auth;
		if (
			this.state.inputAddress.length === 42 &&
			web3.utils.isAddress(this.state.inputAddress)
		) {
			//disable click on elements until bet accepted
			this.changeBtnState();
			if (this.state.inputAddress === '' || this.state.inputValue === '') {
				if (this.state.inputAddress === '') {
					formErrorsAddress.inputAddress = 'Please enter wallet address';
					this.setState({
						formErrorsAddress
					});
					this.changeBtnState();
				} else {
					formErrorsValue.inputValue = 'Please enter value';
					this.setState({
						formErrorsValue
					});
					this.changeBtnState();
				}
				return;
			} else {
				if (
					user.address.toUpperCase() !== this.state.inputAddress.toUpperCase()
				) {
					this.setState({
						successInfo: !this.state.successInfo
					});
					if (this.state.successError) {
						this.setState({
							successError: false
						});
					}
					if (this.state.successAlert) {
						this.setState({
							successAlert: false
						});
					}
					web3.eth.personal
						.unlockAccount(user.address, this.state.addressPass, 120)
						.then(() => {
							web3.eth
								.sendTransaction({
									from: user.address,
									to: this.state.inputAddress,
									value: web3.utils.toWei(this.state.inputValue, 'ether')
								})
								.then(() => {
									this.setState({
										successAlert: !this.state.successAlert,
										successInfo: !this.state.successInfo
									});
									this.getUserBalance();
									this.changeBtnState();
								})
								.catch(err => {
									this.changeBtnState();
									this.setState({
										successInfo: !this.state.successInfo,
										successError: true
									});
									console.log('Failed with error: ' + err);
								});
						});
				} else {
					this.changeBtnState();
					this.setState({
						successError: true
					});
				}
			}
		} else {
			formErrorsAddress.inputAddress = 'Please enter valid wallet address';
			this.setState({
				formErrorsAddress
			});
		}
	};

	render() {
		const { formErrorsAddress } = this.state;
		const { formErrorsValue } = this.state;
		const { user } = this.props.auth;
		return (
			<div className='container-fluid wallet-wrapper'>
				<div className='container wallet-container'>
					<div className='row wallet-row'>
						<div className='col-md-6 deposit-wrap'>
							<p className='col-title'>Deposit:</p>
							<div className='inner-wrap'>
								<p className='betcoin-address'>Betcoin wallet address:</p>
								<p className='myWallet'>{user.address}</p>
								<div className='deposit-btns-wrap'>
									{!this.state.QRvisible ? (
										<div className='btn-group dropdown mb-1'>
											<CopyToClipboard
												text={this.state.walletAddress}
												onCopy={() => this.setState({ copied: true })}>
												<button className='btn deposit-btn'>
													Copy to clipboard
												</button>
											</CopyToClipboard>
											{this.state.copied ? (
												<span className='cory-msg'>Copied</span>
											) : null}
										</div>
									) : null}
									{this.state.QRvisible ? (
										<div className='btn-group dropup mb-1'>
											<button type='button' className='btn deposit-btn'>
												Copy to clipboard
											</button>
										</div>
									) : null}
									{this.state.QRvisible ? (
										<div className='qrdiv col-centered'>
											<QRCode
												value={this.state.walletAddress}
												size={180}
												bgColor={'#ffffff'}
												fgColor={'#000000'}
												level={'L'}
												includeMargin={true}
												renderAs={'canvas'}
											/>
										</div>
									) : null}
									<p className='qrcode' onClick={this.QRcodeShowHide}>
										QR Code
									</p>
								</div>
							</div>
						</div>

						<div className='col-md-6 withdraw-wrap'>
							<p className='col-title'>Withdraw:</p>
							<div className='inner-wrap'>
								{this.state.successAlert ? (
									<div className='row'>
										<div className='alert alert-success alert-dismissible col'>
											<a
												href='#0'
												onClick={this.succesFalse}
												className='close'
												data-dismiss='alert'
												aria-label='close'>
												&times;
											</a>
											<strong>Money sent!</strong>
										</div>
									</div>
								) : null}
								{this.state.successInfo ? (
									<div className='row'>
										<div className='alert alert-info alert-dismissible col'>
											<a
												href='#0'
												onClick={this.InfoFalse}
												className='close'
												data-dismiss='alert'
												aria-label='close'>
												&times;
											</a>
											<strong>Info!</strong> Please wait until money is
											transfered
										</div>
									</div>
								) : null}
								{this.state.successError ? (
									<div className='row'>
										<div className='col alert alert-danger alert-dismissible col'>
											<a
												href='#0'
												onClick={this.ErrorFalse}
												className='close'
												data-dismiss='alert'
												aria-label='close'>
												&times;
											</a>
											<strong>Error!</strong> Can't send funds to this address
										</div>
									</div>
								) : null}
								<input
									autoComplete='off'
									onChange={this.updateAddress}
									className={
										formErrorsAddress.inputAddress.length > 0 ? 'error' : null
									}
									type='text'
									placeholder='Personal wallet address:'
								/>
								{formErrorsAddress.inputAddress.length > 0 && (
									<p className='errorMessage'>
										{formErrorsAddress.inputAddress}
									</p>
								)}
								<input
									autoComplete='off'
									onChange={this.updateValue}
									className={
										formErrorsValue.inputValue.length > 0 ? 'error' : null
									}
									type='number'
									placeholder='Value you want to withdraw:'
								/>
								{formErrorsValue.inputValue.length > 0 && (
									<p className='errorMessage'>{formErrorsValue.inputValue}</p>
								)}

								<button
									disabled={this.state.disablebutton}
									className='betup'
									name='withdrow money'
									onClick={this.handleWithward}>
									Withdraw
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
Withdraw.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(Withdraw);
