import React, { Component } from 'react';
import moment from 'moment-timezone';
import {
	getStartTime,
	getEndTime,
	getRoundOutcome
} from '../../actions/roundDataActions';
import ReactCountdownClock from 'react-countdown-clock';

moment.tz.setDefault('Europe/Belgrade');
global.roundFlag = 5;
global.clearBettingDataFlag = 0;
class RoundData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roundStarted: '',
			roundWillFinish: '',
			roundOutcome: '',
			timeToNextRound: '',
			timeRemainingForBets: '',
			timeReal: '',
			roundTimeUpdated: false,
			timeForClock: 0,
			flag: 5,
			showTimer: false
		};
	}

	componentDidMount() {
		this.betAmountTimer = setInterval(() => {}, 5000);
		this.updateRoundTime();
		this.updateTimes();

		this.timer = setInterval(() => {
			this.updateTimes();

			// Check if round is finished
			if (
				moment(new Date(this.state.roundStarted))
					.add(8, 'minutes')
					.diff(moment(new Date(global.timeNow))) < 0
			) {
				getRoundOutcome.call(this);
				getStartTime.call(this);
			}

			//Update round times in first seconds of new round
			if (
				moment(new Date(this.state.roundStarted))
					.add(5, 'seconds')
					.diff(moment(new Date(global.timeNow))) > 0
			) {
				getStartTime.call(this);
			}
		}, 1000);
	}

	/**
	 * Get current round time
	 */
	updateRoundTime = () => {
		// Check the place to call this func
		getStartTime.call(this);
		getEndTime.call(this);
		getRoundOutcome.call(this);
	};

	/**
	 * Recalculate and update times every second
	 */
	updateTimes = () => {
		if (
			moment(new Date(this.state.roundStarted), [
				'DD/MMM/YYYY HH:mm',
				'MMM dd, yyyy'
			])
				.add(10, 'minutes')
				.diff(moment(global.timeNow)) > 0
		) {
			this.setState({
				timeRemainingForBets: moment(new Date(this.state.roundStarted), [
					'DD/MMM/YYYY HH:mm',
					'MMM dd, yyyy'
				])
					.add(8, 'minutes')
					.diff(moment(global.timeNow)),
				timeReal: moment(new Date(this.state.roundStarted), [
					'DD/MMM/YYYY HH:mm',
					'MMM dd, yyyy'
				])
					.add(9, 'minutes')
					.diff(moment(global.timeNow)),
				timeToNextRound: moment(new Date(this.state.roundStarted), [
					'DD/MMM/YYYY HH:mm',
					'MMM dd, yyyy'
				])
					.add(10, 'minutes')
					.diff(moment(global.timeNow)),
				roundWillFinish: moment(new Date(this.state.roundStarted), [
					'DD/MMM/YYYY HH:mm',
					'MMM dd, yyyy'
				]).add(9, 'minutes')
				//.format('DD/MMM/YYYY HH:mm')
			});
		}

		if (this.state.timeReal > 61000) {
			if (this.state.flag !== 0) {
				this.setState({
					timeForClock: this.state.timeRemainingForBets / 1000,
					flag: 0
				});
			}
		}
		if (this.state.timeReal <= 60000 && this.state.timeReal >= 1000) {
			if (this.state.flag !== 1) {
				this.setState({
					timeForClock: this.state.timeReal / 1000,
					flag: 1
				});
			}
		}
		if (this.state.timeReal < 1000) {
			if (this.state.flag !== 2) {
				this.setState({
					timeForClock: this.state.timeToNextRound / 1000,
					flag: 2
				});
			}
		}
		global.roundFlag = this.state.flag;
	};

	toggleTimer() {
		this.setState({
			showTimer: !this.state.showTimer
		});
	}

	componentWillUnmount = () => {
		clearTimeout(this.timer);
		clearTimeout(this.betAmountTimer);
	};

	render() {
		return (
			<React.Fragment>
				<p className='inner-data-title'>Round Data</p>
				{this.state.roundStarted === '' ? (
					<div className='data-val-wrap'>
						<p>{''}</p>
						<p className='data-white-text'>{''}</p>
					</div>
				) : (
					<div className='data-val-wrap'>
						<p>Round started</p>
						<p className='data-white-text'>
							{moment(this.state.roundStarted).format('HH:mm')}
						</p>
					</div>
				)}
				{this.state.roundWillFinish === '' ? (
					<div className='data-val-wrap mar-bot'>
						<p>{''}</p>
						<p className='data-white-text'>{''}</p>
					</div>
				) : (
					<div className='data-val-wrap mar-bot'>
						<p>Round will finish</p>
						<p className='data-white-text'>
							{moment(this.state.roundWillFinish).format('HH:mm')}
						</p>
					</div>
				)}
				{this.state.showTimer ? (
					<div className='countdown-wrap'>
						<ReactCountdownClock
							closeTimer={this.toggleTimer.bind(this)}
							seconds={this.state.timeForClock}
							color={
								this.state.flag === 0
									? '#66B92E'
									: this.state.flag === 1
									? '#D65B4A'
									: '#2DA1F8'
							}
							alpha={0.9}
							size={100}
							weight={8}
							fontSize={'20px'}
							//onComplete={myCallback}
						/>
						<div className='remaining-time'>
							{this.state.timeReal > 60000 ? (
								<div className='over-circle'>
									<p className='bets-times'>
										{moment(this.state.timeRemainingForBets).format('mm:ss')}
									</p>
									<p className='bet-text'>
										Time for bets{' '}
										{/* {moment(this.state.timeRemainingForBets).format("mm:ss")} */}
									</p>
								</div>
							) : null}
							{this.state.timeReal <= 60000 && this.state.timeReal >= 1000 ? (
								<div className='over-circle'>
									<p className='bets-times'>
										{moment(this.state.timeReal).format('mm:ss')}
									</p>
									<p className='bet-text'>
										Finishing <br /> round{' '}
										{/* {moment(this.state.timeReal).format("mm:ss")} */}
									</p>
								</div>
							) : null}
							{this.state.timeReal < 1000 &&
							this.state.timeToNextRound !== '' ? (
								<div className='over-circle'>
									<p className='bets-times'>
										{moment(this.state.timeToNextRound).format('mm:ss')}
									</p>
									<p className='bet-text'>
										Next round <br /> start{' '}
										{/* {moment(this.state.timeToNextRound).format("mm:ss")} */}
									</p>
								</div>
							) : null}
							{this.state.timeReal < -60000 ||
							this.state.timeReal > 600000 ||
							this.state.timeToNextRound === '' ? (
								<div className='over-circle'>
									<p className='bets-times'>{'--:--'}</p>
									<p className='bet-text'>{''}</p>
								</div>
							) : null}
						</div>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

export default RoundData;
