import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';

import {
	setCurrentUser,
	logoutUser,
	updateUser,
	updateUserAdr
} from './actions/authActions';
import { Provider } from 'react-redux';
import store from './store';

import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import PrivateRoute from './components/private-route/PrivateRoute';
import Dashboard from './components/dashboard/Dashboard';

import './App.css';
import Styles from '../src/scss/index.scss';
import Wallet from './components/wallet/Wallet';
import Terms from './components/terms/Terms';
import Help from './components/help/Help';
import Confirm from './components/confirm/Confirm';

import moment from 'moment-timezone';
import Axios from 'axios';
import ForgotPassword from './components/auth/ForgotPassword';

moment.tz.setDefault('Europe/Belgrade');

global.style = Styles;
global.currentBalance = 0;

// Check for token to keep user logged in
if (localStorage.jwtToken) {
	// Set auth token header auth
	const token = localStorage.jwtToken;
	setAuthToken(token);
	// Decode token and get user info and exp
	const decoded = jwt_decode(token);
	// Set user and isAuthenticated
	store.dispatch(setCurrentUser(decoded));
	// Check for expired token
	const currentTime = global.timeNow / 1000; // to get in milliseconds
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser());
		store.dispatch(updateUser());
		store.dispatch(updateUserAdr());
		// Redirect to loginethvalues
		window.location.href = './login';
	}
}

class App extends Component {
	state = {
		updateServiceDown: false
	};

	componentDidMount = () => {
		// Check if user exists in DB and logout if not
		if (localStorage.getItem('jwtToken') !== null) {
			const decoded = jwt_decode(localStorage.jwtToken);
			Axios.post('/api/users/checkIfUserExist', { id: decoded.id }).then(
				isExistingUser => {
					if (!isExistingUser.data.exist) {
						localStorage.removeItem('jwtToken');
						window.location.href = './login';
					}
				}
			);
		}
		this.checkUpdateService();
		this.serviceCheck = setInterval(() => {
			this.checkUpdateService();
		}, 5000);
	};

	checkUpdateService = () => {
		Axios.post('/api/serviceTimes/getServiceTime').then(serviceTime => {
			if (
				moment(global.timeNow).diff(moment(serviceTime.data.timestamp)) > 10000
			) {
				this.setState({ updateServiceDown: true });
				clearInterval(this.serviceCheck);
			}
		});
	};

	render() {
		return (
			<Provider store={store}>
				<Router>
					{!this.state.updateServiceDown ? (
						<div className='App'>
							<Navbar />
							<Route exact path='/confirm/:id' component={Confirm} />
							<Route exact path='/' component={Landing} />
							<Route exact path='/register' component={Register} />
							<Route exact path='/login' component={Login} />
							<Route exact path='/forgotpassword' component={ForgotPassword} />
							<Switch>
								<PrivateRoute exact path='/dashboard' component={Dashboard} />
							</Switch>
							<Route exact path='/wallet' component={Wallet} />
							<Route exact path='/terms' component={Terms} />
							<Route exact path='/help' component={Help} />
						</div>
					) : (
						<div className='App'>
							<p>Service temporarily unavailable, please try again later...</p>
						</div>
					)}
				</Router>
			</Provider>
		);
	}
}
export default App;
