import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions';
import classnames from 'classnames';

class Popup extends React.Component {
	closePopup = history => {
		//this.props.closePopup;
		//console.log(this.context.history);
		this.context.router.history.push('/login');
	};
	static contextTypes = {
		router: PropTypes.object
	};
	render() {
		return (
			<div className='popup'>
				<div className='popup_inner congratz'>
					<p className='popup-title'>Congratulations</p>
					<p className='popup-reg'>Your registration has been submited</p>
					<p className='popup-text'>
						Please check your e-mail inbox to verify account
					</p>
					<Link to='/login'>Ok</Link>
				</div>
			</div>
		);
	}
}

class Register extends Component {
	constructor() {
		super();
		this.state = {
			name: '',
			email: '',
			password: '',
			password2: '',
			type: 'password',
			type2: 'password',
			isChecked: false,
			mustCheck: false,
			showPopup: false,
			errors: {}
		};
		this.showHide = this.showHide.bind(this);
		this.showHide2 = this.showHide2.bind(this);
	}

	componentDidMount() {
		// If logged in and user navigates to Register page, should redirect them to dashboard
		if (this.props.auth.isAuthenticated) {
			this.props.history.push('/dashboard');
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}
	}

	togglePopup() {
		this.setState({
			showPopup: !this.state.showPopup
		});
	}

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();
		if (this.state.isChecked) {
			const newUser = {
				name: this.state.name,
				email: this.state.email,
				password: this.state.password,
				password2: this.state.password2
			};
			this.props.registerUser(newUser, this.props.history).then(res => {
				fetch(`/email`, {
					method: 'pOSt',
					headers: {
						aCcePt: 'aPpliCaTIon/JsOn',
						'cOntENt-type': 'applicAtion/JSoN'
					},
					body: JSON.stringify({ email: res.data.email })
				})
					.then(res => res.json())
					.then(data => {
						//Redirected
						this.setState({
							showPopup: true
						});
					})
					.catch(
						err => console.log(err),
						this.setState({
							showPopup: false
						})
					);
			});
			//alert("Please confirm your email");
		} else {
			if (!this.state.mustCheck) {
				this.setState({ mustCheck: true });
			}
		}
	};

	showHide(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		});
	}

	showHide2(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			type2: this.state.type2 === 'input' ? 'password' : 'input'
		});
	}

	toggleChange = () => {
		this.setState({
			isChecked: !this.state.isChecked
		});
	};

	render() {
		const { errors } = this.state;

		return (
			<div className='container'>
				<div className='row register-row'>
					<div className='col register-wrap'>
						<Link to='/' className='btn-flat waves-effect bottom-space'>
							<i className='material-icons left'>keyboard_backspace</i> Back to
							home
						</Link>
						<form
							autoComplete='off'
							className='login-form'
							noValidate
							onSubmit={this.onSubmit}>
							<div className='input-field col s12 inputs-wrap'>
								<input
									autoComplete='off'
									onChange={this.onChange}
									value={this.state.name}
									error={errors.name}
									id='name'
									type='text'
									className={classnames('', {
										invalid: errors.name
									})}
								/>
								<label
									className={classnames(
										'',
										this.state.name.length > 0 ? 'text-up' : ''
									)}
									htmlFor='name'>
									Name
								</label>
								<span className='red-text'>{errors.name}</span>
							</div>
							<div className='input-field col s12 inputs-wrap'>
								<input
									autoComplete='off'
									onChange={this.onChange}
									value={this.state.email}
									error={errors.email}
									id='email'
									type='email'
									className={classnames('', {
										invalid: errors.email
									})}
								/>
								<label
									className={classnames(
										'',
										this.state.email.length > 0 ? 'text-up' : ''
									)}
									htmlFor='email'>
									Email
								</label>
								<span className='red-text'>{errors.email}</span>
							</div>
							<div className='input-field col s12 inputs-wrap2'>
								<input
									autoComplete='off'
									onChange={this.onChange}
									value={this.state.password}
									error={errors.password}
									id='password'
									type={this.state.type}
									className={classnames('', {
										invalid: errors.password
									})}
								/>
								<img
									className='eye-img'
									src={require('../../img/eye.svg')}
									alt=''
									onClick={this.showHide}
								/>
								<label
									className={classnames(
										'',
										this.state.password.length > 0 ? 'text-up' : ''
									)}
									htmlFor='password'>
									Password
								</label>
								<span className='red-text no-of-chars'>{errors.password}</span>
								<span className='minimum-characters red-text'>
									Password should contain at least 6 characters
								</span>
							</div>
							<div className='col s12' style={{ paddingLeft: '11.250px' }}>
								<p className='grey-text text-darken-1 have-acc'>
									<input
										autoComplete='off'
										type='checkbox'
										checked={this.state.isChecked}
										onChange={this.toggleChange}
									/>
									I accept and agree in the{' '}
									<Link to='/terms'> terms of use</Link>
								</p>
							</div>
							<div
								className='col s12 loginbtn-wrap'
								style={{ paddingLeft: '11.250px' }}>
								<button
									style={
										{
											// width: "150px",
											// borderRadius: "3px",
											// letterSpacing: "1.5px",
											// marginTop: "1rem"
										}
									}
									type='submit'
									className=''>
									Sign up
								</button>
								{this.state.mustCheck ? <p>Terms must be accepted</p> : null}
							</div>
						</form>
						<div className='center-text'>
							<Link to='/help'> Help </Link>
							<p className='grey-text text-darken-1 have-acc'>
								Already have an account? <Link to='/login'>Log in</Link>
							</p>
						</div>
					</div>
				</div>
				{this.state.showPopup ? (
					<Popup closePopup={this.togglePopup.bind(this)} />
				) : null}
			</div>
		);
	}
}

Register.propTypes = {
	registerUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ registerUser }
)(withRouter(Register));
