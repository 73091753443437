import axios from 'axios';
import moment from 'moment-timezone';

/**
 * Set default timezone
 */
moment.tz.setDefault('Europe/Belgrade');
moment.suppressDeprecationWarnings = true;

// Round start time
export function getStartTime() {
	axios
		.post('/api/rounds/getStartTime')
		.then(res => res.data)
		.then(data => {
			this.setState({
				roundStarted: moment(new Date(data), [
					'DD/MMM/YYYY HH:mm',
					'MMM dd, yyyy'
				]), //.format("DD/MMM/YYYY HH:mm"),
				roundTimeUpdated: true,
				showTimer: true
			});
			const { roundStarted } = this.state;
			this.props.handleStartTime(roundStarted);
		});
}

// Round end time
export function getEndTime() {
	axios
		.post('/api/rounds/getEndTime')
		.then(res => res.data)
		.then(data => {
			this.setState({
				roundWillFinish: moment(new Date(data), [
					'DD/MMM/YYYY HH:mm',
					'MMM dd, yyyy'
				]), //.format("DD/MMM/YYYY HH:mm"),
				showTimer: true
			});
		});
}

// Round Outcome
export function getRoundOutcome() {
	axios
		.post('/api/rounds/getRoundOutcome')
		.then(res => res.data)
		.then(data => {
			this.setState({
				roundOutcome: data
			});
			const { roundOutcome } = this.state;
			this.props.handleRoundOutcome(roundOutcome);
		});
}
