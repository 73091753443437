import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import axios from 'axios';

class Confirm extends Component {
	// A bit of state to give the user feedback while their email
	// address is being confirmed on the User model on the server.
	state = {
		confirming: true,
		msg: ''
	};

	// When the component mounts the mongo id for the user is pulled  from the
	// params in React Router. This id is then sent to the server to confirm that
	// the user has clicked on the link in the email. The link in the email will
	// look something like this:
	//
	// /confirm/5c40d7607d259400989a9d42
	//
	// where 5c40d...a9d42 is the unique id created by Mongo
	componentDidMount = () => {
		const { id } = this.props.match.params;
		axios
			.get(`/email/confirm/${id}`)
			.then(res => {
				if (res.data.msg === 'Your email is confirmed!') {
					let { history } = this.props;
					history.push({
						pathname: '/login',
						emailConfirmed: true
					});
				} else {
					this.setState({
						confirming: false,
						msg: res.data.msg
					});
				}
			})
			.catch(err => console.log(err));
	};

	// While the email address is being confirmed on the server a spinner is
	// shown that gives visual feedback. Once the email has been confirmed the
	// spinner is stopped and turned into a button that takes the user back to the
	// <Landing > component so they can confirm another email address.
	render = () => (
		<div className='confirm'>
			{this.state.confirming ? (
				<p> Wait until email is confirmed </p>
			) : (
				<p>{this.state.msg}</p>
			)}
		</div>
	);
}

Confirm.propTypes = {
	auth: PropTypes.object.isRequired,
	loginUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{ loginUser }
)(withRouter(Confirm));
