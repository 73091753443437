import React, { Component } from 'react';
import Web3 from 'web3';
import nodeUrl from '../../eth-node-config.json';
import moment from 'moment-timezone';
import compiledContract from '../../dapp/build/contracts/BettingApp.json';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAddressPassword } from '../../actions/bettingDataActions';
import axios from 'axios';
import { insertIntoBetHistory } from '../../actions/bettingDataActions';
import {
	userBalance,
	currentBets,
	betsHistory,
} from '../../actions/bettingDataActions';
import Loader from 'react-loader-spinner';
import classnames from 'classnames';
import SHA256 from 'crypto-js/sha256';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

moment.tz.setDefault('Europe/Belgrade');
global.bettype = 0;
global.stake = 0;
global.estimatedWin = 0;

class Popup extends React.Component {
	render() {
		return (
			<div className='popup'>
				<div className='popup_inner'>
					<h3>
						<b>
							<i>Congratulations</i>
						</b>
					</h3>
					<h5>Your bet has been accepted!</h5>
					<b>Bet : {global.bettype === 2 ? 'Up' : 'Down'}</b>
					<b>Stake : {global.stake}</b>
					<b>Estimated win : {global.estimatedWin}</b>
					<button onClick={this.props.closePopup}>Ok</button>
				</div>
			</div>
		);
	}
}

/**
 * Create web3 instance
 */
const web3 = new Web3(nodeUrl.url);

/*
 * Get address from compiled contract
 */
const contractAddress = compiledContract.deployedAddress;

/**
 * Create contract instance
 */
const contractInstance = new web3.eth.Contract(
	compiledContract.abiDefinition,
	contractAddress
);

let userPassword = '';
let userAddress = '';

class Betting extends Component {
	state = {
		currentBalance: '',
		stake: '',
		total: '',
		estimatedWin: '',
		betType: '',
		betting: false,
		placedBet: '',
		betAccepted: '',
		betMinAccepted: '',
		stakeValueEmpty: '',
		roundid: '',
		timeIsValid: '',
		possibleUpWinning: 0,
		possibleDownWinning: 0,
		upCoeficient: 0,
		downCoeficient: 0,
		realBetAmount: 0,
		realCost: 0,
		typeOfBet: 0,
		formErrors: {
			inputValue: '',
		},
		showPopup: false,
		disablebutton: false,
		showToast: false,
	};

	showCurrentBetsTrick = () => {
		this.getRoundID().then((result) => {
			this.props.currentBets(result._id);
		});
	};

	disableButtons = () => {
		if (global.roundFlag === 0) {
			this.setState({
				disablebutton: false,
			});
		} else {
			this.setState({
				disablebutton: true,
			});
		}
	};

	componentDidMount() {
		this.getUserAddressAndPassword();
		this.getRoundID();
		this.timer = setInterval(() => {
			this.disableButtons();
		}, 1000);
	}
	componentWillUnmount = () => {
		clearTimeout(this.timer);
	};

	// Get user balance
	getUserBalance = () => {
		const web3 = new Web3(nodeUrl.url);
		const { user } = this.props.auth;
		if (user.address !== '' && user.address !== undefined) {
			web3.eth.getBalance(user.address).then((balance) => {
				if (balance) {
					const x = web3.utils.fromWei(balance, 'ether');
					this.props.userBalance(x);
				}
			});
		}
	};

	getUserAddressAndPassword = () => {
		const { user } = this.props.auth;
		global.userID = user.id;
		userAddress = user.address;
		if (user.address !== '' && user.address !== undefined) {
			axios
				.post('/api/addresses/getAddressPass', { address: user.address })
				.then((response) => {
					userPassword = response.data.password;
				});
		}
	};

	togglePopup() {
		this.setState({
			showPopup: !this.state.showPopup,
		});
	}

	getRoundID = () => {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/roundsHistory/getRoundData')
				.then((response) => response.data)
				.then((data) => {
					this.setState(
						{
							roundid: data._id,
						},
						() => {
							resolve(data);
						}
					);
				});
		});
	};

	disablePaste = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	disableDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	// Update value state
	updateValue = (e) => {
		let currentStake = e.target.value;
		currentStake = currentStake.replace(',', '.');

		// filter stake value
		let filteredStake = '';
		let firstDot = true;
		for (let i = 0; i < currentStake.length; i++) {
			if (!isNaN(currentStake.charAt(i)) || currentStake.charAt(i) === '.') {
				if (currentStake.charAt(i) === '.') {
					if (firstDot) {
						firstDot = false;
						filteredStake += currentStake.charAt(i);
						continue;
					}
					continue;
				}
				filteredStake += currentStake.charAt(i);
			}
		}

		// check number of decimals
		const numOfDecimals = filteredStake.split('.')[1];
		if (numOfDecimals && numOfDecimals.length > 5) {
			filteredStake = filteredStake.substring(0, filteredStake.length - 1);
		}
		const { value } = e.target;
		let formErrors = this.state.formErrors;
		formErrors.inputValue =
			value.length === 0 ? 'Please enter a bet value' : '';
		this.setState(
			{
				formErrors,
				stake: filteredStake,
			},
			() => {
				const { stake } = this.state;
				this.getPossibleWinning(stake);
				this.props.handleNewBet(stake);
			}
		);
	};

	hideMinBetStatusMsg = () => {
		this.setState({
			betMinAccepted: false,
		});
	};

	hideWaitForRoundMsg = () => {
		this.setState({
			timeIsValid: false,
		});
	};

	hideBetStatusMsg = () => {
		this.setState({
			betAccepted: null,
		});
	};

	hideEmptyValueStatusMsg = () => {
		this.setState({
			stakeValueEmpty: false,
		});
	};

	stakeValueCheck = () => {
		if (this.state.stake === '') {
			this.setState({
				stakeValueEmpty: true,
			});
			return true;
		}
		return false;
	};

	handleTypeOfBetUp = () => {
		if (this.state.typeOfBet === 2) {
			this.setState({ typeOfBet: 0 });
		} else {
			this.setState({ typeOfBet: 2 });
		}
	};

	handleTypeOfBetDown = () => {
		if (this.state.typeOfBet === 1) {
			this.setState({ typeOfBet: 0 });
		} else {
			this.setState({ typeOfBet: 1 });
		}
	};

	// Reset state if bet was invalid
	resetBet = () => {
		sessionStorage.setItem('type', '');
		document.getElementById('inputCenteredText').disabled = false;
		this.setState({
			disablebutton: false,
		});
		this.setState({
			betAccepted: false,
			betting: false,
			betMinAccepted: false,
			stakeValueEmpty: false,
			timeIsValid: false,
			typeOfBet: 0,
		});
	};

	//Insert into bethistory
	insertIntoBetH = (bettype, value, roundid, betid, timestamp) => {
		const { user } = this.props.auth;
		this.props.insertIntoBetHistory(
			user.id,
			bettype,
			value,
			roundid,
			betid,
			timestamp
		);
	};

	handleBet = (e) => {
		this.getUserAddressAndPassword();
		//check if the field is empty
		if (this.stakeValueCheck()) {
			return;
		}

		if (this.state.stake >= 0.005) {
			// disable bet buttons
			this.setState({
				disablebutton: true,
			});
			this.setState({ betting: true });
			document.getElementById('inputCenteredText').disabled = true;

			// error if value field is empty
			let formErrors = this.state.formErrors;
			if (this.state.state === '') {
				formErrors.inputValueE = 'Please enter a bet value';
				this.setState({ formErrors });
				this.setState({
					disablebutton: false,
				});
				this.setState({ betting: false });
				document.getElementById('inputCenteredText').disabled = false;
				return;
			} else {
				// check type of bet
				if (this.state.typeOfBet === 0) {
					this.resetBet();
					this.notifyUpDown();
					this.setState({
						showToast: true,
					});
					return;
				}
				const placedBetNumber = this.state.typeOfBet;
				const name = placedBetNumber === 2 ? 'bet up' : 'bet down';
				this.setState({ placedBet: name });

				// check if time is right
				if (
					moment(new Date(this.props.roundStartTime))
						.add(8, 'minutes')
						.diff(moment(global.timeNow)) < 0
				) {
					this.setState({ betAccepted: false, timeIsValid: true });
					this.resetBet();
					console.log(
						'Bet is not accepted! Round is not active' + this.state.timeIsValid
					);
					return;
				}

				// check if user have enough funds
				web3.eth.getBalance(userAddress).then((balance) => {
					if (
						web3.utils.fromWei(balance, 'ether') <
						parseFloat(this.state.stake.replace(',', '.')) + 0.0025
					) {
						this.setState({ betAccepted: false });
						this.resetBet();
						this.notifyNotEnoughFunds();
						this.setState({
							showToast: true,
						});
						return;
					} else {
						// unlock user's address
						web3.eth.personal
							.unlockAccount(userAddress, userPassword, 120)
							.then(() => {
								// first get roundid and then insert
								// finally place the BET
								// ---> save bet details into MongoDB
								this.getRoundID().then(() => {
									const betID = SHA256(
										userAddress +
											this.state.roundid +
											Math.random().toString(36).substring(2, 15)
									).toString();
									global.bettype = this.state.typeOfBet;
									global.stake = this.state.stake;
									if (this.state.typeOfBet === 1) {
										global.estimatedWin = this.state.possibleDownWinning;
									} else {
										global.estimatedWin = this.state.possibleUpWinning;
									}
									this.insertIntoBetH(
										placedBetNumber,
										web3.utils.toWei(this.state.stake, 'ether'),
										this.state.roundid,
										betID,
										moment(global.timeNow)
									);
									// ---> save bet on the blockchain
									contractInstance.methods
										.purchaseBet(placedBetNumber, betID)
										.send({
											from: userAddress,
											value: web3.utils.toWei(this.state.stake, 'ether'),
											gas: '300000',
											gasPrice: '15000000000',
										})
										.then((receipt) => {
											if (receipt) {
												sessionStorage.setItem('type', this.state.stake);
												this.setState({ betAccepted: true, betting: false });
												document.getElementById(
													'inputCenteredText'
												).disabled = false;
												this.setState({
													disablebutton: false,
												});

												this.setState({ stake: '', betMinAccepted: false });
												if (this.state.typeOfBet === 1) this.notifyBetDown();
												else this.notifyUpBet();
												this.showCurrentBetsTrick();

												// update that bet is saved on BC
												axios.post('/api/insertHistory/updatebh', {
													betID: betID,
												});

												this.setState({
													typeOfBet: 0,
													possibleUpWinning: 0,
													possibleDownWinning: 0,
													realCost: 0,
												});
												this.getUserBalance();
												this.props.betsHistory();
											} else {
												this.resetBet();
											}
										})
										.catch((err) => {
											this.resetBet();
											this.notifyNotEnoughFunds();
											this.setState({
												showToast: true,
											});
											console.log('Failed with error: ' + err);
										});
								});
							});
					}
				});
			}
		} else {
			this.setState({
				betMinAccepted: true,
				betAccepted: null,
				showRoundResult: false,
			});
		}
	};

	//get possible winning
	getPossibleWinning = (inputValue) => {
		let transactionFee = 0.0025;
		transactionFee = parseFloat('0.0025');
		if (parseFloat(inputValue.replace(',', '.')) !== 0) {
			this.setState({
				realCost: parseFloat(
					(parseFloat(inputValue.replace(',', '.')) + transactionFee).toFixed(
						10
					)
				),
			});
			if (inputValue === '') {
				this.setState({
					realCost: 0,
				});
			}
			if (this.state.realCost === 'NAN') {
				this.setState({
					realCost: 0,
				});
			}
			if (isNaN(this.state.realCost)) {
				this.setState({
					realCost: 0,
				});
			}

			//possible down bet
			if (parseFloat(global.totalBetUpAmount) === 0) {
				this.setState({
					possibleDownWinning: 0,
				});
			} else {
				this.setState(() => ({
					realBetAmount:
						parseFloat(global.totalBetAmount) +
						parseFloat(inputValue.replace(',', '.')) -
						(parseFloat(global.totalBetUpAmount) * 10) / 100,
				}));

				this.setState((state) => ({
					downCoeficient:
						parseFloat(state.realBetAmount) /
						(parseFloat(global.totalBetDownAmount) +
							parseFloat(inputValue.replace(',', '.'))),
				}));

				this.setState((state) => ({
					possibleDownWinning:
						parseFloat(inputValue.replace(',', '.')) *
						parseFloat(state.downCoeficient),
				}));
				//console.log('Down winning ' + this.state.possibleDownWinning);
			}

			//possible up bet
			if (parseFloat(global.totalBetDownAmount.replace(',', '.')) === 0) {
				this.setState({
					possibleUpWinning: 0,
				});
			} else {
				this.setState(() => ({
					realBetAmount:
						parseFloat(global.totalBetAmount) +
						parseFloat(inputValue.replace(',', '.')) -
						(parseFloat(global.totalBetDownAmount) * 10) / 100,
				}));

				this.setState((state) => ({
					upCoeficient:
						parseFloat(state.realBetAmount) /
						(parseFloat(global.totalBetUpAmount) +
							parseFloat(inputValue.replace(',', '.'))),
				}));

				this.setState((state) => ({
					possibleUpWinning:
						parseFloat(inputValue.replace(',', '.')) *
						parseFloat(state.upCoeficient),
				}));
				//	console.log(' Up winning ' + this.state.possibleUpWinning);
			}
		}
	};
	notifyUpBet = () =>
		toast.success('Bet up!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

	notifyBetDown = () =>
		toast.success('Bet down', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			className: 'toast-2nd',
		});

	notifyNotEnoughFunds = () => {
		toast.error('You do not have enough funds', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	};

	notifyUpDown = () => {
		toast.warn('Please select type of bet (UP/DOWN)', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	};

	render() {
		return (
			<div className='dashboard-sidebar lighter-gray-bg'>
				{this.state.showToast ? (
					<ToastContainer
						position='top-right'
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnVisibilityChange
						draggable
						pauseOnHover
						className='toast-1st'
					/>
				) : null}
				{this.state.showPopup ? (
					<Popup closePopup={this.togglePopup.bind(this)} />
				) : null}
				<p className='betting-title'>Betting</p>
				<div className='cur-balance-wrap'>
					<p> YOUR STAKE (ETH) </p>
					<div>
						<input
							autoComplete='off'
							id='inputCenteredText'
							name='inputValue'
							type='text'
							onPaste={this.disablePaste.bind(this)}
							onDrop={this.disableDrop.bind(this)}
							onChange={this.updateValue}
							placeholder='Stake value (ETH)'
							value={this.state.stake}
						/>
					</div>
				</div>
				<div className='total-eth-wrap'>
					<div>
						<p className='sidebar-total'>Total (ETH): {this.state.realCost}</p>
						<p>average transaction fee is 0.0025 ETH</p>
					</div>
					<p className='white-total'>{this.state.total}</p>
				</div>
				<div className='sidebar-estimated'>
					<p>Estimated win (ETH) {this.state.estimatedWin}</p>
					{
						<div className='estimated-up-down'>
							<div className='estimated-up'>
								<img
									className='arrow-up'
									src={require('../../img/up-arrow.svg')}
									alt=''
								/>
								<p>
									{!isNaN(parseFloat(this.state.possibleUpWinning))
										? parseFloat(this.state.possibleUpWinning.toFixed(10))
										: 0}
								</p>
							</div>
							<div className='estimated-down'>
								<img
									className='arrow-up'
									src={require('../../img/down-arrow.svg')}
									alt=''
								/>
								<p>
									{!isNaN(parseFloat(this.state.possibleDownWinning))
										? parseFloat(this.state.possibleDownWinning.toFixed(10))
										: 0}
								</p>
							</div>
						</div>
					}
				</div>
				{this.state.stakeValueEmpty ? (
					<div className='alert alert-danger alert-dismissible'>
						<a
							href='#0'
							className='close'
							onClick={this.hideEmptyValueStatusMsg}>
							&times;
						</a>
						Enter valid stake value
					</div>
				) : null}
				{this.state.betMinAccepted ? (
					<div className='alert alert-danger alert-dismissible'>
						<a href='#0' className='close' onClick={this.hideMinBetStatusMsg}>
							&times;
						</a>
						Your bet was rejected. Minimum bet is 0.005.
					</div>
				) : null}
				{this.state.timeIsValid ? (
					<div className='alert alert-danger alert-dismissible'>
						<a href='#0' className='close' onClick={this.hideWaitForRoundMsg}>
							&times;
						</a>
						Wait for next round to start
					</div>
				) : null}
				<div className='side-btn-wrap'>
					<button
						disabled={this.state.disablebutton || this.state.betting}
						className={classnames(
							'betup',
							this.state.typeOfBet === 2 ? 'up-pressed' : ''
						)}
						name='bet up'
						id='betup'
						onClick={this.handleTypeOfBetUp}>
						<img
							className='arrow-up'
							src={require('../../img/up-arrow.svg')}
							alt=''
						/>
						Up
					</button>
					<button
						disabled={this.state.disablebutton || this.state.betting}
						className={classnames(
							'betdown',
							this.state.typeOfBet === 1 ? 'down-pressed' : ''
						)}
						name='bet down'
						id='betdown'
						onClick={this.handleTypeOfBetDown}>
						<img
							className='arrow-down'
							src={require('../../img/down-arrow.svg')}
							alt=''
						/>
						Down
					</button>
				</div>
				<div className='place-btn-wrap'>
					<button
						className={
							(global.roundFlag !== 0 ? 'inactive-btn' : '') ||
							(this.state.betting ? 'accepted-btn' : '')
						}
						disabled={this.state.disablebutton || this.state.betting}
						onClick={this.handleBet}>
						{this.state.betting ? (
							<Loader type='Circles' color='#00BFFF' height='20' width='20' />
						) : null}
						Place your bet
					</button>
				</div>
			</div>
		);
	}
}

Betting.propTypes = {
	getAddressPassword: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	getAddressPassword,
	insertIntoBetHistory,
	userBalance,
	currentBets,
	betsHistory,
})(Betting);
