import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';

// Register User
export const registerUser = (userData, history) => dispatch => {
	return new Promise((resolve, reject) => {
		axios
			.post('/api/users/register', userData)
			.then(res => {
				resolve(res);
				//history.push("/login")}
			})
			.catch(err =>
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			);
	});
};

// Update user
export const updateUser = idToUpdate => dispatch => {
	return new Promise((resolve, reject) => {
		axios
			.post('/api/users/updateData', { id: idToUpdate, flogin: false })
			.then(res => {
				resolve(res);
			})
			.catch(err =>
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			);
	});
};

// Update user address
export const updateUserAdr = idToUpdate => dispatch => {
	return new Promise((resolve, reject) => {
		axios
			.post('/api/users/updateAddress', { id: idToUpdate })
			.then(res => {
				resolve(res);
			})
			.catch(err =>
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			);
	});
};

// Login - get user token
export const loginUser = userData => dispatch => {
	//console.log(userData);
	axios
		.post('/api/users/login', userData)
		.then(res => {
			// Save to localStorage

			// Set token to localStorage
			const { token } = res.data;
			localStorage.setItem('jwtToken', token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);
			// Set current user
			dispatch(setCurrentUser(decoded));
		})
		.catch(err =>
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		);
};

// Set logged in user
export const setCurrentUser = decoded => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded
	};
};

// User loading
export const setUserLoading = () => {
	return {
		type: USER_LOADING
	};
};

// Log user out
export const logoutUser = history => dispatch => {
	// Remove token from local storage
	localStorage.removeItem('jwtToken');
	// Remove auth header for future requests
	setAuthToken(false);
	history.push('/login');
	// Set current user to empty object {} which will set isAuthenticated to false
	dispatch(setCurrentUser({}));
};
