import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
			flogin: false,
			type: 'password',
			errors: {},
			emailConfirmedNotice: false
		};
		this.showHide = this.showHide.bind(this);
	}

	componentDidMount() {
		// If logged in and user navigates to Login page, should redirect them to dashboard
		if (this.props.auth.isAuthenticated) {
			this.props.history.push('/dashboard');
		}
		// Show email confirmation notice
		if (this.props.location.emailConfirmed) {
			this.setState(
				{
					emailConfirmedNotice: true
				},
				() => {
					this.notifyEmailConfirmed();
				}
			);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.auth.isAuthenticated) {
			this.props.history.push('/dashboard');
		}

		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}
	}

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();

		const userData = {
			email: this.state.email,
			password: this.state.password,
			flogin: this.state.flogin
		};

		this.props.loginUser(userData);
	};

	showHide(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		});
	}

	notifyEmailConfirmed = () => {
		toast.success('Email confirmed successfully', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true
		});
	};

	render() {
		const { errors } = this.state;

		return (
			<div className='container login'>
				<div className='row login-row'>
					<div className='col centeredEl'>
						{this.state.emailConfirmedNotice ? (
							<ToastContainer
								position='top-right'
								autoClose={5000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnVisibilityChange
								draggable
								pauseOnHover
							/>
						) : null}
						<Link to='/' className='btn-flat waves-effect bottom-space'>
							<i className='material-icons left'>keyboard_backspace</i> Back to
							home
						</Link>
						<form
							autoComplete='off'
							className='login-form'
							noValidate
							onSubmit={this.onSubmit}>
							<div className='input-field col s12 inputs-wrap'>
								<input
									shrink
									autoComplete='off'
									onChange={this.onChange}
									value={this.state.email}
									error={errors.email}
									id='email'
									type='email'
									className={classnames('', {
										invalid: errors.email
									})}
								/>
								<label
									className={classnames(
										'',
										this.state.email.length > 0 ? 'text-up' : ''
									)}
									htmlFor='email'
									shrink>
									Username or email
								</label>
								<span className='red-text'>{errors.email}</span>
							</div>
							<div className='input-field col s12 inputs-wrap'>
								<input
									shrink
									autoComplete='off'
									onChange={this.onChange}
									value={this.state.password}
									error={errors.password}
									id='password'
									type={this.state.type}
									className={classnames('', {
										invalid:
											errors.password ||
											errors.passwordincorrect ||
											errors.emailnotfound ||
											errors.emailnotconfirmed
									})}
								/>
								<img
									className='eye-img'
									src={require('../../img/eye.svg')}
									onClick={this.showHide}
									alt=''
								/>
								<label
									className={classnames(
										'',
										this.state.password.length > 0 ? 'text-up' : ''
									)}
									htmlFor='password'
									shrink>
									Password
								</label>
								<span className='red-text'>
									{errors.password}
									{errors.passwordincorrect
										? 'Invalid e-mail or password'
										: null}
									{errors.emailnotfound ? 'Invalid e-mail or password' : null}
									{errors.emailnotconfirmed
										? 'Email not confirmed , please check Your inbox'
										: null}
								</span>
							</div>
							<div className='col loginbtn-wrap'>
								<button type='submit'>Login</button>
								<Link to='/forgotpassword'>Forgot password?</Link>
								<Link to='/help'> Help</Link>
								<p className='grey-text text-darken-1'>
									Don’t have account yet?
									<Link to='/register'>Register</Link>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	loginUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ loginUser }
)(Login);
