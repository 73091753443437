import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import classnames from 'classnames';

class Login extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
			flogin: false,
			type: 'password',
			userDoesNotExist: false,
			errors: {}
		};
		this.showHide = this.showHide.bind(this);
	}

	componentDidMount() {
		// If logged in and user navigates to Login page, should redirect them to dashboard
		if (this.props.auth.isAuthenticated) {
			this.props.history.push('/dashboard');
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.auth.isAuthenticated) {
			this.props.history.push('/dashboard');
		}

		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}
	}

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();
		const userData = {
			email: this.state.email,
			password: this.state.password,
			flogin: this.state.flogin
		};
		if (userData) {
			fetch(`/forgotpassword`, {
				method: 'pOSt',
				headers: {
					aCcePt: 'aPpliCaTIon/JsOn',
					'cOntENt-type': 'applicAtion/JSoN'
				},
				body: JSON.stringify({ email: userData.email })
			})
				.then(res => res.json())
				.then(data => {
					this.props.history.push('/login');
				})
				.catch(
					err => console.log(err),
					this.setState({ userDoesNotExist: true })
				);
		}
	};

	showHide(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		});
	}

	render() {
		const { errors } = this.state;

		return (
			<div className='container login'>
				<div className='row login-row'>
					<div className='col centeredEl'>
						<Link to='/' className='btn-flat waves-effect bottom-space'>
							<i className='material-icons left'>keyboard_backspace</i> Back to
							home
						</Link>
						<form
							autoComplete='off'
							className='login-form'
							noValidate
							onSubmit={this.onSubmit}>
							<div className='input-field col s12 inputs-wrap'>
								<input
									autoComplete='off'
									onChange={this.onChange}
									value={this.state.email}
									error={errors.email}
									id='email'
									type='email'
									className={classnames('', {
										invalid: errors.email
									})}
								/>
								<label
									className={classnames(
										'',
										this.state.email.length > 0 ? 'text-up' : ''
									)}
									htmlFor='email'>
									Username or email
								</label>
								<span className='red-text'>{errors.email}</span>
							</div>
							{this.state.userDoesNotExist ? (
								<p> User does not exist in database </p>
							) : null}
							<div className='col loginbtn-wrap'>
								<button type='submit'>Reset password</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	loginUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ loginUser }
)(Login);
