import React, { Component } from 'react';
import Web3 from 'web3';
import nodeUrl from '../../eth-node-config.json';
import compiledContract from '../../dapp/build/contracts/BettingApp.json';

const web3 = new Web3(nodeUrl.url);
const contractAddress = compiledContract.deployedAddress;
const contractInstance = new web3.eth.Contract(
	compiledContract.abiDefinition,
	contractAddress
);

class ValueData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalBetAmount: 0,
			totalBetUpAmount: 0,
			totalBetDownAmount: 0,
		};
	}

	getTotalBetAmount = () => {
		contractInstance.methods
			.totalBetAmount()
			.call()
			.then((response) => {
				//this.totalBetAmount.setState(web3.utils.fromWei(response,'ether'));
				this.setState({
					totalBetAmount: web3.utils.fromWei(response, 'ether'),
				});
				global.totalBetAmount = this.state.totalBetAmount;
			});
	};

	getTotalBetUpAmount = () => {
		contractInstance.methods
			.totalBetUpAmount()
			.call()
			.then((response) => {
				//this.totalBetUpAmount.setState(web3.utils.fromWei(response,'ether'));
				this.setState({
					totalBetUpAmount: web3.utils.fromWei(response, 'ether'),
				});
				global.totalBetUpAmount = this.state.totalBetUpAmount;
			});
	};

	getTotalBetDownAmount = () => {
		contractInstance.methods
			.totalBetDownAmount()
			.call()
			.then((response) => {
				//this.totalBetDownAmount.setState(web3.utils.fromWei(response,'ether'));
				this.setState({
					totalBetDownAmount: web3.utils.fromWei(response, 'ether'),
				});
				global.totalBetDownAmount = this.state.totalBetDownAmount;
			});
	};

	componentDidMount = () => {
		this.getTotalBetAmount();
		this.getTotalBetUpAmount();
		this.getTotalBetDownAmount();

		this.valueUpdate = setInterval(() => {
			this.getTotalBetAmount();
			this.getTotalBetUpAmount();
			this.getTotalBetDownAmount();
			if (global.clearBettingDataFlag === 1) {
				global.clearBettingDataFlag = 0;
				this.setState({
					totalBetUpAmount: 0,
					totalBetDownAmount: 0,
					totalBetAmount: 0,
				});
			}
		}, 10000);
	};

	componentWillUnmount = () => {
		clearInterval(this.valueUpdate);
	};

	render() {
		return (
			<React.Fragment>
				<p className='inner-data-title'>Value Data</p>

				<div className='data-val-wrap'>
					<p>Bet Up Amount (ETH)</p>
					<p className='data-white-text'>
						{parseFloat(this.state.totalBetUpAmount)}
					</p>
				</div>
				<div className='data-val-wrap mar-bot'>
					<p>Bet Down Amount (ETH)</p>
					<p className='data-white-text'>
						{parseFloat(this.state.totalBetDownAmount)}{' '}
					</p>
				</div>
				<div className='data-val-wrap-bot'>
					<p className='data-text-blue'>
						{parseFloat(this.state.totalBetAmount)}
					</p>
					<p className='big-text'>Total Bet Amount (ETH)</p>
				</div>
			</React.Fragment>
		);
	}
}

export default ValueData;
